import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import { useBreakPoint } from 'hooks';

import { AddButton } from 'components/AddButton';
import { SwitchWithLabel } from 'components/SwitchWithLabel';

export const RightHeaderContent = () => {
  const [isOn, setOn] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const isShowPlayers = searchParams.get('filter') === 'players';

  const breakPoint = useBreakPoint();

  useEffect(() => {
    if (isShowPlayers) {
      setOn(true);
    }

    if (!searchParams.get('filter')) {
      setOn(false);
    }
  }, [searchParams, isShowPlayers]);

  const handleChange = () => {
    setOn(!isOn);

    setSearchParams((prev) => {
      prev.set('filter', `${!isOn ? 'players' : 'clans'}`);

      return prev;
    });
  };

  const handleAddClan = () => {
    navigate('/admin-panel/clan/new');
  };

  const handleAddPlayer = () => {
    navigate('/admin-panel/player/new');
  };

  return (
    <div
      className={cn('flex flex-col', {
        '!flex-row gap-[16px]': breakPoint !== 'mobile',
      })}
    >
      <AddButton
        label={isShowPlayers ? 'Пользователь' : 'Подразделение'}
        onClick={isShowPlayers ? handleAddPlayer : handleAddClan}
        className={
          breakPoint === 'mobile'
            ? 'text-tpg_base !justify-start'
            : 'flex-row-reverse'
        }
      />
      <SwitchWithLabel
        label="Пользователи"
        checked={isOn}
        onChange={handleChange}
        className={breakPoint === 'mobile' ? 'text-tpg_base' : ''}
      />
    </div>
  );
};
