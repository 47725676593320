import { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import { useAppDispatch } from 'hooks';
import { clanActions, playerActions } from 'store';
import { useDebouncedCallback } from 'use-debounce';

import TextInput from 'components/ui/TextInput';

export const SearchInputByTable = () => {
  const [searchValue, setSearchValue] = useState({
    playerValue: '',
    clanValue: '',
  });

  const [searchParams] = useSearchParams();

  const isPlayerQueryParam = useMemo(
    () => searchParams.get('filter') === 'players',
    [searchParams]
  );

  const containerRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isPlayerQueryParam) {
      dispatch(clanActions.setQuerySearch(''));

      setSearchValue((prev) => ({ ...prev, clanValue: '' }));
    } else {
      dispatch(playerActions.setQuerySearch(''));

      setSearchValue((prev) => ({ ...prev, playerValue: '' }));
    }
  }, [isPlayerQueryParam]);

  const setQuerySearch = useDebouncedCallback((value: string) => {
    if (isPlayerQueryParam) {
      dispatch(playerActions.setQuerySearch(value));
    } else {
      dispatch(clanActions.setQuerySearch(value));
    }
  }, 300);

  const handleChange = (value: string) => {
    setQuerySearch(value);

    if (isPlayerQueryParam) {
      setSearchValue((prev) => ({ ...prev, playerValue: value }));
    } else {
      setSearchValue((prev) => ({ ...prev, clanValue: value }));
    }
  };

  return (
    <div ref={containerRef} className="relative flex justify-center">
      <TextInput
        value={
          isPlayerQueryParam ? searchValue.playerValue : searchValue.clanValue
        }
        placeholder="Начните поиск"
        searchIcon
        isReset
        className={cn('w-full bg-dark max-w-[540px] xl:max-w-[300px]')}
        theme="dark"
        onChange={handleChange}
      />
    </div>
  );
};
