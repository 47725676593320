import { FC } from 'react';
import cn from 'classnames';

import { Switch } from 'components/ui';
import { SwitchProps } from 'components/ui/Switch';

interface SwitchWithLabelProps extends SwitchProps {
  label: string;
  className?: string;
}

export const SwitchWithLabel: FC<SwitchWithLabelProps> = ({
  label,
  checked,
  disabled,
  onChange,
  size = 's',
  className,
}) => (
  <div className={cn('flex flex-row gap-[4px] items-center', className)}>
    <span className="tpg-c2">{label}</span>
    <Switch
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      size={size}
    />
  </div>
);
