import { FC } from 'react';
import cn from 'classnames';
import { useBreakPoint } from 'hooks';

interface EmtyScreenProps {
  text: string;
  className?: string;
}

export const EmtyScreen: FC<EmtyScreenProps> = ({ text, className }) => {
  const breakPoint = useBreakPoint();

  return (
    <div
      className={cn(
        'flex justify-center items-center w-full h-[calc(100vh-105px)] bg-light rounded-[10px]',
        {
          'h-[calc(100vh-180px)]':
            breakPoint === 'mobile' || breakPoint === 'tablet',
        },
        className
      )}
    >
      <span className="tpg-h4 text-tpg_light">{text}</span>
    </div>
  );
};
